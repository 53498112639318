.tether-element, .tether-element:after, .tether-element:before, .tether-element *, .tether-element *:after, .tether-element *:before {
  box-sizing: border-box; }

.tether-element {
  position: absolute;
  display: none; }
  .tether-element.tether-open {
    display: block; }

.tether-element.tether-theme-basic {
  max-width: 100%;
  max-height: 100%; }
  .tether-element.tether-theme-basic .tether-content {
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    font-family: inherit;
    background: #fff;
    color: inherit;
    padding: 1em;
    font-size: 1.1em;
    line-height: 1.5em; }
